import { createBrowserRouter } from "react-router-dom";
import Index from "../pages";
import BookWriter from "../pages/BookWriter";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <Index />,
        errorElement: <h1>Not Found</h1>
    },
    {
        path: "/login",
        element: <Index />,
        errorElement: <h1>Not Found</h1>
    },
    {
        path: "/signup",
        element: <Index />,
        errorElement: <h1>Not Found</h1>
    },
    {
        path: "/editor",
        element: <BookWriter />,
        errorElement: <h1>Not Found</h1>,
    },

])