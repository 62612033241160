import React, { useState } from 'react'
import { RemoveRedEye } from '@mui/icons-material'

import { signUpClass } from '../../utilities';
import Swal from 'sweetalert2';


function Signup({ setActiveForm }) {
    const [showpass, setShowPass] = useState(false);

    const [signupDets, setSignupDets] = useState({
        email: '',
        password: '',
        first_name: '',
        last_name: '',
    })

    function SignUp(e) {
        e.preventDefault()
        signUpClass(signupDets.email, signupDets.password, signupDets.first_name, signupDets.last_name, 'bookwriter_signup').then((response) => {
            let result = response;
            if (result.data.status == 'success') {
                window.localStorage.setItem('book_user', JSON.stringify(result.data.data));
                Swal.fire({
                    title: 'Success',
                    text: 'Sign up Successfully',
                    timer: 1000,
                })
                setActiveForm('login')
            } else {
                Swal.fire({
                    title: 'Error',
                    text: result.data.message,
                    timer: 1000,
                })
            }
        })
    }

    return (
        <form onSubmit={SignUp}>
            <div className='mb-3'>
                <label htmlFor='first_name'>First Name </label>
                <input onChange={(e) => { setSignupDets({ ...signupDets, first_name: e.target.value }) }} type="text" required name="first_name" className='form-control' placeholder='please enter your first name' />
            </div>
            <div className='mb-3'>
                <label htmlFor='last_name'>Last Name </label>
                <input onChange={(e) => { setSignupDets({ ...signupDets, last_name: e.target.value }) }} type="text" required name="last_name" className='form-control' placeholder='please enter your last name' />
            </div>
            <div className='mb-3'>
                <label htmlFor='email_address'>Email Address</label>
                <input onChange={(e) => { setSignupDets({ ...signupDets, email: e.target.value }) }} type="email" required name="email_address" className='form-control' placeholder='please enter your email address' />
            </div>
            <div className='mb-3'>
                <label htmlFor='password'>Password</label>
                <span className='form-control '>
                    <input onChange={(e) => { setSignupDets({ ...signupDets, password: e.target.value }) }} type={showpass ? 'text' : "password"} className='border-0 col-10' name="password" placeholder='please enter your password' />
                    <span onClick={() => { setShowPass(!showpass) }} className='col-2'>
                        <RemoveRedEye />
                    </span>
                </span>

            </div>

            <button className='buttonComp'>Sign up</button>
            <p>Don't have an account ? <a onClick={() => { setActiveForm('login') }} >Login</a></p>
        </form>
    )
}

export default Signup
