import { Close } from '@mui/icons-material'
import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { generateKeyword, GenerateTableOfContents, WriteBookDescription } from '../../utilities'
import { Personas } from '../personalities'

export default function Ava({ makeFalse, person, firstPrompt, }) {
    const navigate = useNavigate();

    const persona = Personas[person]
    const [interaction, setInteraction] = useState(false)
    const [response, setResponse] = useState('');
    const [reply, setReply] = useState('');
    const [loading, setLoading] = useState(false);
    const [showInputs, setShowInputs] = useState(false);
    const [generated, SetGenerated] = useState(false);
    const [rand, setRand] = useState('');
    const [description, setDescription] = useState('');
    const [keywords_g, setKeywordsG] = useState('');
    const [array_of_keywords, setArrayOfKeywords] = useState([]);
    const [showKeywords, setShowKeywords] = useState(false);
    const [category_type, setCategoryType] = useState('')

    const [editTitle, setEditTitle] = useState({
        title: generated.title,
        subtitle: generated.subtitle,
    })

    const [book, setBook] = useState({
        bookTitle: generated.title,
        bookSubtitle: generated.subtitle,
        category: category_type,
        pageNumber: '',
    });
    useEffect(() => {
        if (localStorage.getItem('category_type') == null) {
        } else {
            setCategoryType(window.localStorage.getItem('category_type'));
            // setBook({ ...book, category: localStorage.getItem('category_type') })
        }
    }, [])

    useEffect(() => {
        let desItem = localStorage.getItem('book_description');

        if (desItem !== null) {
            setDescription(JSON.parse(localStorage.getItem('book_description')).book_description_content);
            setRand(Math.random())
        }
    }, [])
    useEffect(() => {
        let desItem = localStorage.getItem('keywords_generated');
        if (desItem !== null) {
            setKeywordsG(JSON.parse(localStorage.getItem('keywords_generated')).keywords);
        }
    }, [showKeywords])
    useEffect(() => {
        const newArray = keywords_g.split(',');
        setArrayOfKeywords(newArray);
    }, [keywords_g])


    useEffect(() => {
        if (localStorage.getItem('gent') == null) {
        } else {
            SetGenerated(JSON.parse(localStorage.getItem('gent')))
            setEditTitle(JSON.parse(localStorage.getItem('gent')))
        }
    }, [loading, rand])


    function generateContents() {
        if (book.bookTitle == '' || book.bookSubtitle == '' || book.category == '' || book.pageNumber == '') {
            setLoading(false)
            return setReply('kindly fill the fields below')
        }

        setLoading(true)

        setReply('Great, generating table of contents ...');
        GenerateTableOfContents(generated, book.category, book.pageNumber).then((response) => {
            if (response === true) {
                setLoading(false)
                setReply('Table of content has been generated, navigating you to the editor')
                navigate('/editor')
            } else {
                setLoading(false)
                // let e = 'null'

            }
        })
    }


    function generateDescription() {

        if (book.bookTitle == '' || book.bookSubtitle == '' || book.category == '') {
            setLoading(false)
            return setReply('kindly fill the fields below')
        }

        setLoading(true)

        setReply('Great, generating book description...');

        WriteBookDescription(generated, book.category).then((response) => {
            if (response.status === true) {
                setLoading(false)
                setReply('Book Description has been generated, navigating you to the editor')
                navigate('/editor')
                return
            } else {
                setLoading(false)
                // let e = 'null
            }
        })


    }

    function generateKeywords() {
        if (book.bookTitle == '' || book.bookSubtitle == '' || book.category == '') {
            setLoading(false)
            return setReply('kindly fill the fields below')
        }

        setLoading(true)

        setReply('Great, generating keywords...');

        generateKeyword(generated, book.category).then((response) => {
            if (response.status === true) {
                setLoading(false)
                setReply('Keywords has been generated')
                setShowInputs(false)
                setShowKeywords(true)
                return
            } else {
                setReply('An Error Occured Click generate again!')
                setLoading(false)
            }
        })

    }


    useEffect(() => {
        if (response === 'No') {
            setReply("Okay, You can generate a captivating title with this input below. Enter a few keywords and click to generate!")
            window.scrollTo(0, 50);
        } else if (response === 'Yes') {
            setReply("Great, Let's Get Started! \n\n Kindly Enter Your Book Title and Subtitle below.")
            setShowInputs(true)
        }
    }, [response])
    return (
        <div className='bg-white p-3 col-11 col-lg-8 m-auto rounded-3'>
            <div onClick={makeFalse} className='text-end'>
                <Close />
            </div>
            {
                !interaction ?
                    <div>
                        <div className="text-center col-md-5 m-lg-auto">
                            <img className='img-fluid' src={persona.image} alt={persona.name} loading="lazy" />
                        </div>
                        <h5>
                            Hi, I am {persona.name},
                        </h5>
                        <p>
                            {persona.selfDes}
                        </p>


                        <div className='py-3 '>
                            <button onClick={() => {
                                setInteraction(true)
                                setKeywordsG('')
                                window.localStorage.removeItem('keywords_generated');
                                setShowInputs(true)
                                setReply('Do you have a Book Title and SubTitle?')
                            }} className='buttonComp' type="submit">{person === 1 ? 'Lets Generate Keywords' : 'Let Start!!'}</button>
                        </div>
                    </div>

                    :
                    <div>
                        <div className="text-start row align-items-center justify-content-center ">
                            <div className='d-flex col-lg-3 align-items-center'>
                                <div>
                                    <img style={
                                        {
                                            height: '60px',
                                            width: '60px',
                                            borderRadius: '50px',
                                            resizeMode: 'cover',
                                        }
                                    } className='me-2' src={persona.image} alt={persona.name} loading="lazy" />
                                </div>
                                <div>
                                    <p className='fw-bold'>
                                        {persona.name}
                                    </p>
                                </div>
                            </div>
                            <div className='col-lg-8 text-start'>
                                <p className='fs-5'>  -- {reply}</p>
                            </div>


                        </div>
                        <div>
                            {
                                response === "No" ?
                                    <div className="text-center">
                                        <button onClick={makeFalse} className='buttonComp'>
                                            Okay Thanks!
                                        </button>
                                    </div>
                                    :
                                    <div className='justify-content-center'>
                                        {showKeywords ?
                                            <div className='text-start p-3'>
                                                {keywords_g}
                                            </div>
                                            :
                                            <div>

                                                {showInputs &&
                                                    <div className='mb-3 col-lg-8 py-3 m-auto'>
                                                        {/* <label htmlFor="">Book Title</label> */}
                                                        <input
                                                            onBlur={() => {
                                                                window.localStorage.setItem('gent', JSON.stringify(editTitle));
                                                                setRand(Math.random())
                                                            }}
                                                            onChange={(e) => {
                                                                setEditTitle({ ...editTitle, title: e.target.value })
                                                            }} type="text" value={editTitle.title} required className='form-control mb-3' placeholder='Enter Book Title' />
                                                        <input
                                                            onBlur={() => {
                                                                window.localStorage.setItem('gent', JSON.stringify(editTitle));
                                                                setRand(Math.random())
                                                            }}
                                                            onChange={(e) => {
                                                                setEditTitle({ ...editTitle, subtitle: e.target.value })
                                                            }} type="text" value={editTitle.subtitle} required className='form-control mb-3' placeholder='Enter Book Subitle' />
                                                        <input onChange={(e) => {
                                                            setRand(Math.random())
                                                            setBook({ ...book, category: e.target.value })
                                                        }}
                                                            value={book.category}
                                                            type="text" required className='form-control mb-3' placeholder='Enter Category e.g Non-Fiction>Health and Fitness>Vegeterian Diets' />
                                                        {person !== 0 || person === 1 &&
                                                            <input onChange={(e) => {
                                                                setBook({ ...book, pageNumber: e.target.value })
                                                            }} type="number" required className='form-control mb-3' placeholder='Enter expected number of pages' />
                                                        }
                                                    </div>
                                                }

                                                {showInputs ?
                                                    <div>
                                                        {person === 0 ?
                                                            loading ? <CircularProgress /> :
                                                                (
                                                                    <button onClick={generateDescription} className='buttonComp me-3'>
                                                                        Generate Book Description
                                                                    </button>
                                                                )

                                                            :
                                                            person === 1 ?
                                                                loading ? <CircularProgress /> :
                                                                    (
                                                                        <button onClick={generateKeywords} className='buttonComp me-3'>
                                                                            Generate Key Words
                                                                        </button>
                                                                    )
                                                                :
                                                                loading ? <CircularProgress /> :
                                                                    (
                                                                        <button onClick={generateContents} className='buttonComp me-3'>
                                                                            Generate Table of Contents
                                                                        </button>
                                                                    )
                                                        }
                                                    </div>

                                                    :
                                                    <div className='d-flex justify-content-center'>

                                                        <button onClick={() => { setResponse('Yes') }} className='buttonComp me-3'>
                                                            Yes
                                                        </button>

                                                        <button onClick={() => { setResponse('No') }} className='btn btn-secondary'>
                                                            No
                                                        </button>

                                                    </div>

                                                }
                                            </div>
                                        }
                                    </div>
                            }

                        </div>
                    </div>

            }
        </div>
    )
}
