export const menuitems = [
    {
        title: 'Editor',
        path: '/editor',
        sub_menu: [

        ]

    },
    {
        title: 'Author',
        sub_menu: [

        ]

    },
    {
        title: 'Services',
        sub_menu: [
            {
                title: 'Book Description Writer',
                path: '/generate-book-description'
            },
            {
                title: 'Publishing Consultant',
                path: '/publishing-consultant'
            },
            {
                title: 'Table of Content Generator',
                path: '/table-of-content'
            },
            {
                title: 'Non-Fiction Book Writer',
                path: '/nonfiction-author'
            },

        ]

    },
    {
        title: 'Contact Us',
        sub_menu: [

        ]

    },
]