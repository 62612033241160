
import '../src/styles/style.css'
import '../src/styles/main.min.css'
import { RouterProvider } from 'react-router-dom';
import { router } from './components/router';
import { registerLicense } from '@syncfusion/ej2-base';

registerLicense('ORg4AjUWIQA/Gnt2V1hiQlRPd19dVHxLflF1VWJYdVpyflBGcC0sT3RfQF5jQH5ad0dnUHtYeHRWRg==')

function App() {
  return (
    // <div className="App">
    // <LetsGetStarted />
    <div>
      <RouterProvider router={router} />

      {/* <div className='d-flex justify-content-center align-items-center p-5'>
        <div className='shadow d-flex col-10'>
          <div className='bg-dark text-white p-5 rounded-3'>
            <h2>This the first part</h2>
          </div>
          <div className='bg-white p-5 rounded-3 '>

            <div className='border border-1 rounded-4 p-4 mb-3 shadow '>
              <p className='fs-3'>
                first part
              </p>
            </div>
            <div className='border border-1 col-10 m-auto rounded-4 p-4 mb-3'>
              <p className='fs-3'>
                first part
              </p>
            </div>

            <div className='border border-1 col-10 m-auto rounded-4 p-4 '>
              <p className='fs-3'>
                first part
              </p>
            </div>
          </div>
        </div>
      </div> */}


    </div>
    // <BookWriter />
    // </div>
  );
}

export default App;
