export const Personas = [
    {
        "name": "Ava",
        "title": "Book Description Writer",
        "description": "Introducing our AI-powered Book Description Writer—a literary virtuoso skilled in creating captivating book blurbs. Craft irresistible book synopses that entice readers and enhance your book's discoverability. Elevate your storytelling with our automated book description service.",
        "selfDes": "I'm here to weave magic into your book blurbs, crafting synopses that'll have readers hooked from the get-go. Let's elevate your storytelling together and make your book an irresistible discovery for readers everywhere!",
        "image": require('../images/ava.png'),
        "path": "/generate-key-words"
    },
    {
        "name": "Maya",
        "title": "Publishing Consultant",
        "description": "Navigate the publishing world with confidence, guided by our AI Publishing Consultant. Gain insights from an industry expert, receive personalized advice on manuscript enhancement, cover design, and marketing strategies. Unleash the potential of your book with our comprehensive publishing consulting AI.",
        "selfDes": " your AI Publishing Consultant. Let's conquer the publishing world together. Get personalized advice on your manuscript, cover design, and marketing strategies. Unleash your book's full potential with my expertise!",
        "image": require('../images/george.png'),
        "path": "/publishing-consultant"
    },
    {
        "name": "Dave",
        "title": "Table of Content Generator",
        "description": "Streamline your writing process with our Table of Content Generator AI. Organize your ideas effortlessly into a reader-friendly structure. Create intuitive navigation for your readers, enhancing their reading experience. Try our automated table of contents tool for seamless content organization.",
        "selfDes": "The maestro behind our Table of Content Generator. Let's make your writing journey a breeze! I'm here to effortlessly organize your ideas into a reader-friendly structure, giving your audience an intuitive roadmap through your content. Elevate your reader's experience with seamless navigation. Dive in and let's create a table of contents that turns your ideas into a captivating journey!",
        "image": require('../images/dave.png'),
        "path": "/table-of-content"
    },
    {
        "name": "George",
        "title": "Non-Fiction Book Writer",
        "description": "Harness the expertise of our Non-Fiction Book Writer AI—a knowledgeable companion for your writing journey. Craft insightful chapters that educate and engage readers. With in-depth research and eloquent prose, our AI non-fiction writer helps you convey complex concepts with clarity and finesse.",
        "selfDes": "Let's team up for a stellar writing journey. I'll help you craft insightful chapters that educate and engage readers. With in-depth research and eloquent prose, we'll tackle complex concepts with clarity and finesse. Let's make your non-fiction book shine!",
        "image": require('../images/george.png'),
        "path": "/nonfiction-author"
    }
]
