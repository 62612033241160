import { CloseRounded } from '@mui/icons-material'
import React from 'react'
import { tutorialJson } from './tutorials'

function MainComp({ activateTutorial, skipTut }) {
    const guideWelcome = tutorialJson.editorPage[0].letsGetStarted;
    return (
        // <div className='bg-white p-3 col-11 col-lg-8 m-auto rounded-3'>

        <div className='justify-content-center ' style={
            {
                width: '100%',
                height: '100vh',
                position: 'fixed',
                top: 0,
                left: 0,
                backgroundColor: '#0D3F8460',
                zIndex: 1000,
            }}>
            <div style={{ top: '20%', position: 'relative' }} className='bg-white text-dark p-lg-5 p-2 col-11 col-lg-5 m-auto rounded-3'>
                {/* {route == '/' && */}
                <div onClick={() => {
                }} className='text-end'>
                    <CloseRounded />
                </div>
                <div className='text-center'>
                    <img style={{
                        height: '250px',
                    }} className='img-fluid mb-3' src={require('../../images/guide.png')} alt="" />
                    <p>
                        {guideWelcome.instructions}
                    </p>
                </div>

                <div className='d-flex justify-content-between'>
                    <button onClick={activateTutorial} className="buttonComp">Let's Go!!</button>
                    <button onClick={skipTut} className='btn btn-danger btn-sm'>Skip</button>
                </div>
            </div>

        </div>
    )
}

export default MainComp
