import { Configuration, OpenAIApi } from "openai";
import { isJson, OPENAPIKEY, ORGANKEY } from '../keys';
const configuration = new Configuration({
    organization: ORGANKEY,
    apiKey: OPENAPIKEY,
});
const openai = new OpenAIApi(configuration);

export async function Generate(keywords) {
    const completion = await openai.createChatCompletion({
        model: "gpt-3.5-turbo",
        messages: [{
            "role": "system",
            "content": "As a Book Publishing Consultant, I am knowledgeable in book publishing strategies and have expertise in optimizing book elements for maximum visibility and reader engagement. I will provide professional insights and recommendations tailored to your specific goals"
        },
        {
            role: "user",
            content: `Dear Book Publishing Consultant, I'm in the process of creating a nonfiction book for Amazon Kindle Direct Publishing. I need your expertise in optimizing the book title and subtitle. Based on the comma-separated entry keywords I provide, please suggest the most optimum combination that aligns with highly searched keywords, offers fresh in-demand angles to the topic, and effectively represents the content. Here are the details Entry Keywords-(${keywords}). Please provide a compelling book title and a captivating subtitle that will attract readers and enhance the discoverability of the book on Amazon. Please write in English language and Please respond with only the title and subtitle details in  JSON format`
        }],
    })

    const gent = completion.data.choices[0].message.content;
    if (isJson(gent) === true) {
        localStorage.setItem('gent', gent);
        return true;
    } else {
        console.log('an error occured')
        return false;
    }

}
