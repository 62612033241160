import React, { useEffect, useState } from 'react'
import TopNav, { BottomNav } from '../components/layoucomp/NavComponents';
import PersonalitiesRev from '../components/homepageComp/personalitiesRev';
import { Generate, } from '../controllers/generate';
import { MenuBook, } from '@mui/icons-material';
import Ava from '../components/AiModals/Ava';

export default function Index() {
    const [active, setActive] = useState(true)
    const [loading, setLoading] = useState(false)
    const [notify, setNotify] = useState('')
    const [keywords, setKeywords] = useState('')
    const [maintext, setMainText] = useState('')
    const [loadingMainText, setLoadingMain] = useState('')

    // const [text, setText] = useState()
    const [loadingtext, setLText] = useState("Loading...")

    const text = "Let's Write A Book..."
    useEffect(() => {
        if (active) {
            const animateText = () => {
                const textArr = [];
                let i = 0;
                if (active) {
                    const interval = setInterval(() => {
                        if (i < text.length) {
                            textArr.pop();

                            textArr.push(text[i]);
                            textArr.push('|');
                            setMainText(textArr.join(''));
                            i++;
                        } else {
                            clearInterval(interval);
                            setTimeout(() => {
                                setMainText(''); // Clear the mainText state
                                animateText(); // Restart the animation after 2 seconds
                            }, 5000); // 2 seconds
                        }
                    }, 100); // Adjust the interval as needed
                    return () => clearInterval(interval);

                } else {

                }
            };
            animateText();
        } else {
            setMainText('Let\'s Write A Book...')
        }
    }, [active]);
    useEffect(() => {
        if (active) {
            const animateText = () => {
                const textArr = [];
                let i = 0;
                if (active) {
                    const interval = setInterval(() => {
                        if (i < loadingtext.length) {
                            textArr.push(loadingtext[i]);
                            setLoadingMain(textArr.join(''));
                            i++;
                        } else {
                            clearInterval(interval);
                            setTimeout(() => {
                                setLoadingMain(''); // Clear the mainText state
                                animateText(); // Restart the animation after 2 seconds
                            }, 5000); // 2 seconds
                        }
                    }, 100); // Adjust the interval as needed
                    return () => clearInterval(interval);

                } else {

                }
            };
            animateText();
        } else {
            setLoadingMain('Loading...')
        }
    }, [active, loadingtext]);

    const [selectedPersona, setSelectedPersona] = useState(0);
    const [showPersona, setShowPersona] = useState(false)
    function TitleGenerate() {
        setLoading(true)
        if (keywords === '') {
            setLoading(false)
            return setNotify('kindly fill the fields below')
        }
        setNotify('')
        Generate(keywords).then((response) => {
            if (response === true) {
                localStorage.setItem('keywords', keywords);
                setLoading(false)
                window.location.href = '/editor'
            }
        }).catch((error) => {
            console.log(error)
        })

    }
    function choosePersona(item, index) {
        setShowPersona(true);
        setSelectedPersona(index);
    }
    function makeFalse() {
        setShowPersona(false);
    }
    return (
        <>
            <div>
                <TopNav />
                <div className='bg-main mainhome text-light justify-content-center align-items-center d-flex py-2 py-lg-5 px-2 px-lg-5'>
                    <div className='text-center'>
                        {!loading ? <h2 onClick={() => {
                            // clearInterval(interval)
                            setActive(false)
                        }} className='display-1'>
                            {maintext}
                        </h2>
                            :
                            <h2 onClick={() => {
                                // clearInterval(interval)
                                setActive(false)
                            }} className='display-1'>
                                {loadingMainText}
                            </h2>
                        }
                        <div className='col-lg-8 m-auto py-1 py-lg-5 '>
                            <div id="generateBox" className='input-group form-control align-items-center justify-content-around'>
                                <input onChange={(e) => { setKeywords(e.target.value) }} type="text" className='border-0 p-4 col-8 w-75 ' placeholder='enter your key words seperated by coma, and we will generate a title for you' />
                                <div className='text-center'>
                                    <MenuBook fontSize='large' />
                                </div>
                            </div>

                        </div>
                        <div className='py-1 py-lg-3'>
                            <button className='buttonComp col-lg-3 col-6 p-4' onClick={TitleGenerate}>Generate</button>
                        </div>
                        <div className='col-lg-7 px-2 py-2 py-lg-4 m-auto'>
                            <p className='fs-4'>Writing a book has never been easy, with few keywords and a click generate complete manuscripts and ideas for your next big book.</p>
                        </div>
                    </div>

                </div>
                <div>
                    <PersonalitiesRev choosePersona={choosePersona} />
                </div>



                {
                    showPersona &&
                    <div style={{
                        width: '100%',
                        height: '100vh',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        backgroundColor: '#0D3F8460',
                        zIndex: 1000,

                    }} className="text-center container-fluid d-flex justify-content-center align-items-center">

                        <Ava makeFalse={makeFalse} person={selectedPersona} />


                    </div>
                }



                <BottomNav />
            </div>
        </>
    )
}
