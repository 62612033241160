import React, { useEffect, useRef, useState } from 'react'
import TopNav from '../components/layoucomp/NavComponents'
import Draggable from 'react-draggable';
import AccordionComp from '../components/AccordionComp';
import { CircularProgress } from '@mui/material';
import EditorFile from '../components/Editor';
import { CheckLogin, convertJSONToBookText, editSectionContent, ExpandBookChapter, GenerateBookTitle, GenerateTableOfContents, WriteBookDescription, WriteTheChapters } from '../utilities';
import { Cancel, Close, Edit, FastForward, FastRewind, LockOpen, LockOutlined, Menu } from '@mui/icons-material';
import Swal from 'sweetalert2';
import { CToast, CToastBody, CToaster, CToastHeader } from '@coreui/react';
import MainComp from '../components/TutorialExperience/mainComp';
import { tutorialJson } from '../components/TutorialExperience/tutorials';


// console.log(openai)
export default function BookWriter() {
    const [generated, SetGenerated] = useState('');
    const [contents, SetContents] = useState('');
    const [keywordsArray, setKeyWordsArray] = useState('');
    const [category, setCategory] = useState('');
    const [pageNumber, setPageNumber] = useState('')
    const [fullbookContent, setFullbookContent] = useState('')
    const [fullbookJson, setFullbookJson] = useState('')
    const [description, setDescription] = useState('');

    const [loading, setLoading] = useState(false);
    const [notify, setNotify] = useState('')
    const [updateChap, setUpdateChap] = useState('')
    const [loggedIn, setLoggedIn] = useState(false);

    const [editTitle, setEditTitle] = useState({
        title: generated.title,
        subtitle: generated.subtitle,
    })
    const [editTit, setEditTit] = useState(false);
    const [editSub, setEditSub] = useState(false);

    const [tutorialText, setTutorialText] = useState({
        item: '',
        howTo: '',
        furtherInfo: '',
    })

    const [rand, setRand] = useState('');

    useEffect(() => {
        if (localStorage.getItem('gent') == null) {
        } else {
            SetGenerated(JSON.parse(localStorage.getItem('gent')))
            setEditTitle(JSON.parse(localStorage.getItem('gent')))
        }
    }, [loading, rand])
    useEffect(() => {
        let desItem = localStorage.getItem('book_description');
        if (desItem !== null) {
            setDescription(JSON.parse(localStorage.getItem('book_description')).book_description_content);
        }
    }, [loading, description, rand])
    useEffect(() => {
        CheckLogin().then((response) => {
            setLoggedIn(!response)
        })
    }, [loggedIn])

    useEffect(() => {
        if (localStorage.getItem('book_array') == null) {
        } else {
            const bookText = convertJSONToBookText(localStorage.getItem('book_array'));
            const bookJson = JSON.parse(localStorage.getItem('book_array'));
            setFullbookJson(bookJson)
            setFullbookContent(bookText);
        }
    }, [updateChap])
    useEffect(() => {
        if (localStorage.getItem('keywords') == null) {
        } else {
            setKeyWordsArray(localStorage.getItem('keywords').split(','));
        }
    }, [loading])
    useEffect(() => {
        if (localStorage.getItem('titlecontents') == null) {
        } else {
            SetContents(localStorage.getItem('titlecontents'))
        }
    }, [loading])
    useEffect(() => {
        if (localStorage.getItem('fullbookContent') == null) {
        } else {
            setFullbookContent(JSON.parse(localStorage.getItem('fullbookContent')))
        }
    }, [loading])

    useEffect(() => {
        if (localStorage.getItem('category_type') == null) {
        } else {
            setCategory(localStorage.getItem('category_type'))
        }
    }, [loading, rand])
    const [toast, addToast] = useState(0)
    const toaster = useRef()
    function exampleToast(message, title,) {
        return (
            <CToast
                delay={120000}
            >
                <CToastHeader closeButton>
                    <svg
                        className="rounded me-2"
                        width="20"
                        height="20"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="xMidYMid slice"
                        focusable="false"
                        role="img"
                    >
                        <rect width="100%" height="100%" fill="#007aff"></rect>
                    </svg>
                    <div className="fw-bold me-auto">{title}</div>
                    {/* <small>7 min ago</small> */}
                </CToastHeader>
                <CToastBody>{message}</CToastBody>
            </CToast>
        )
    }

    function generateIntros(e) {
        if (e !== 'null') {
            e.preventDefault();
        }
        setLoading(true)
        setNotify('Generating Title ...')
        if (keywordsArray === '' || category === '' || pageNumber === '') {
            setLoading(false)
            setTutorialText({
                item: 'generateFirst',
                howTo: '🙄',
                furtherInfo: 'Well? You need to give me keywords and categories to work with. I also need a page number too',
            })
            return setNotify('kindly fill the fields below')
        }
        GenerateBookTitle(category, pageNumber, keywordsArray).then((response) => {
            if (response === true) {
                WriteBookDescription(generated, category).then((response) => {
                    if (response.status === true) {
                        setNotify('')
                        Swal.fire({
                            title: 'Title and Subtile Generated',
                            text: 'The title and subtitle is generated successfully',
                            timer: 2000,
                            icon: "success"
                        })
                        setRand(Math.random());
                        setLoading(false)
                        return
                    } else {
                        setLoading(false)
                    }
                })
                nextTut()
            } else {
                Swal.fire({
                    title: 'An Error Occured Please Retry again',
                    text: 'The title and subtitle is not generated ',
                    timer: 2000,
                    icon: "error"
                })
                setLoading(false)

            }
        })
    }


    function generateDescription() {
        setLoading(true)
        if (category === '' || generated === '') {
            setLoading(false)
            return setNotify('Kindly add a Category')
        }
        WriteBookDescription(generated, category).then((response) => {
            if (response.status === true) {
                setNotify('')
                Swal.fire({
                    title: 'Book Description Generated',
                    text: 'The description is generated successfully',
                    timer: 2000,
                    icon: "success"
                })
                setRand(Math.random());
                setLoading(false)
                return
            } else {
                setLoading(false)
            }
        })
    }

    function generateContents(e) {
        if (e !== 'null') {
            e.preventDefault();
        }
        setLoading(true)
        if (generated === '' || category === '' || pageNumber === '') {
            setLoading(false)
            setTutorialText({
                item: 'generateFirst',
                howTo: '🙄',
                furtherInfo: 'Well? You need to give me categories to work with. I also need a page number too',
            })
            return setNotify('kindly fill the fields below')
        }
        setNotify('')
        GenerateTableOfContents(generated, category, pageNumber, description).then((response) => {
            if (response === true) {
                setLoading(false)
                setNotify('')
                Swal.fire({
                    title: 'Contents Generated',
                    text: 'The contents are generated successfully',
                    timer: 2000,
                    icon: "success"
                })
                nextTut()
            } else {
                setLoading(false)

                Swal.fire({
                    title: 'Contents fail to generated',
                    text: 'The contents failed to generate, retry again',
                    timer: 2000,
                    icon: "error"
                })
            }
        })
    }

    async function checkForEmpty() {

        addToast(exampleToast('checking for skipped chapters', 'loading ...'));


        let book_array = JSON.parse(localStorage.getItem('book_array'));

        let contents_loop = JSON.parse(contents).contents;

        async function processContent(content, i, length) {
            try {
                const response = await WriteTheChapters(content, i, length, generated);
                if (response.status === true) {
                    return response.data;
                } else {
                    console.log(response.data);
                    return null;
                }
            } catch (error) {
                console.error("Error:", error);
                return null;
            }
        }

        const storedContents = book_array.map(item => item.chapter_title);
        const filteredContents = contents_loop.filter((chapter) => {
            return !storedContents.some(title => title.includes(chapter.chapter_title));
        }
        );

        if (filteredContents.length > 0) {
            for (let i = 0; i < filteredContents.length; i++) {
                let item_place = contents_loop.findIndex((content) => content === filteredContents[i]);

                const processedContent = await processContent(filteredContents[i], item_place, contents_loop.length);

                if (processedContent) {
                    // console.log(processedContent);
                    const index = contents_loop.indexOf(filteredContents[i]);
                    // console.log(index)
                    book_array.splice(index, 0, processedContent);
                    window.localStorage.setItem('book_array', JSON.stringify(book_array));

                    let message = `${filteredContents[i].chapter_title} has been generated`;
                    addToast(exampleToast(message, 'Success'));
                    setUpdateChap(Math.random())
                }
            }
        } else {
            Swal.fire({
                title: `Success`,
                text: "Book Fully Generated, Open Menu to Expand Chapters",
                timer: 2000,
                icon: "success"
            })
            nextTut();
        }
    }

    async function WriteAChapter() {
        window.localStorage.removeItem('book_array');
        setUpdateChap(Math.random())
        let book_array = [];
        let contents_loop = JSON.parse(contents).contents;
        async function processContent(content, i, length) {
            try {
                const response = await WriteTheChapters(content, i, length, generated);
                if (response.status === true) {
                    return response.data;
                } else {

                    return null;
                }
            } catch (error) {
                console.error("Error:", error);
                return null;
            }
        }
        setLoading(true);
        // Process contents and add to book_array
        for (let i = 0; i < contents_loop.length; i++) {
            const processedContent = await processContent(contents_loop[i], i, contents_loop.length);
            if (processedContent) {
                book_array.push(processedContent);
                window.localStorage.setItem('book_array', JSON.stringify(book_array));
                setUpdateChap(Math.random())
                let message = `${contents_loop[i].chapter_title} has been generated`;
                addToast(exampleToast(message, 'Success'));

            }
            if (i < contents_loop.length - 1) {
                await new Promise(resolve => setTimeout(resolve, 1000)); // 1-second delay
            }
        }

        setLoading(false);

        window.localStorage.setItem('book_array', JSON.stringify(book_array));
        Swal.fire({
            title: `Success`,
            text: "Book Fully Generated, Open Menu to Expand Chapters",
            timer: 2000,
            icon: "success"
        })
        checkForEmpty();
    }

    async function ExpandChapters() {
        setLoading(true)
        for (let chap_index = 0; chap_index < fullbookJson.length; chap_index++) {
            const chapter = fullbookJson[chap_index];
            const selectedSection = chapter.sections;
            for (let section_index = 0; section_index < selectedSection.length; section_index++) {
                const section = selectedSection[section_index];
                try {
                    const response = await ExpandBookChapter(section, generated, chapter.chapter_title);
                    if (response.status === true) {
                        const updated = editSectionContent(fullbookJson, chap_index, section_index, response.data.expanded_content);
                        window.localStorage.setItem('book_array', JSON.stringify(updated));
                        setUpdateChap(Math.random());

                        // Add a delay between API calls to avoid rate limiting
                        await new Promise(resolve => setTimeout(resolve, 1000)); // Adjust the delay as needed
                    } else {

                    }
                } catch (error) {
                    console.error(error);
                }
            }
            setLoading(false)

        }
    }

    const [showcontrol, setShowControls] = useState(true)
    const [locked, setLocked] = useState(false)
    const [showTable, setShowTable] = useState(false)
    const [showTutor, setShowTutor] = useState(true);


    const [element1, setElement1] = useState({ top: 0, left: 0 });
    const [tutorialIndex, setTutorialIndex] = useState('');
    const [skip, setSkip] = useState(true);

    function activateTutorial() {
        setSkip(false)
        setShowTutor(false)
        setTutorialIndex(0);
    }
    function skipTut() {
        setSkip(true)
        setShowTutor(false)
    }
    useEffect(() => {
        let tutorialTxt = tutorialJson.editorPage[1].generateTitle.instructions[tutorialIndex];
        if (tutorialTxt !== undefined) {
            setTutorialText({
                item: tutorialTxt.item,
                howTo: tutorialTxt.howTo,
                furtherInfo: tutorialTxt.furtherInfo,
            })
            handleMove()
        }
        // console.log(tutorialIndex)
    }, [tutorialIndex])


    const handleMove = () => {
        if (!skip) {
            const rect1 = document.getElementById('element1').getBoundingClientRect();

            const constantElement = document.getElementById('element2')
            const viewportHeight = window.innerHeight;
            const viewportWidth = window.innerWidth;
            if (constantElement !== null) {
                const rect2 = document.getElementById('element2').getBoundingClientRect();

                let topPosition = rect2.top - rect1.height;
                let leftPosition = rect2.left - rect1.width;

                if (leftPosition < 0) {
                    leftPosition = Math.abs(leftPosition)
                }
                if (topPosition < 0) {
                    topPosition = Math.abs(topPosition)
                }
                setElement1({
                    top: topPosition,
                    left: leftPosition,
                });
            }
        }

    };

    const nextTut = () => {
        let length = tutorialJson.editorPage[1].generateTitle.instructions.length
        if (tutorialIndex <= length - 1) {
            if (tutorialIndex === 3 && generated === '') {
                setTutorialText({
                    item: 'generateFirst',
                    howTo: '🙁',
                    furtherInfo: 'Click on the generate title First',
                })
            } else if (tutorialIndex === 5 && contents === '') {
                setTutorialText({
                    item: 'generateFirst',
                    howTo: '🙁',
                    furtherInfo: 'Click on the generate Table of Content First',
                })
            } else if (tutorialIndex === 9) {
                setTutorialIndex(10);
                setTutorialText({
                    item: 'generateFirst',
                    howTo: '🙁',
                    furtherInfo: 'Click on the generate Table of Content First',
                })
            } else {
                setTutorialIndex(tutorialIndex + 1);
            }
        }
    }
    const prevTut = () => {
        if (!(tutorialIndex <= 0)) {
            setTutorialIndex(tutorialIndex - 1);
        }
    }

    return (
        <div className='dimmerClass'>
            <div className='containter-fluid'>
                {showTutor &&
                    <MainComp activateTutorial={activateTutorial} skipTut={skipTut} />
                }
            </div>

            {/* <div className='bg-white d-flex justify-content-between' style={{
                position: 'absolute',
                border: '2px solid blue',
                zIndex: 100,
            }}> */}
            {!skip &&
                <div
                    className='shadow  p-3'
                    id="element1"
                    style={{
                        background: '#202d3e',
                        borderBottomLeftRadius: '20px',
                        borderTopLeftRadius: '20px',
                        borderTopRightRadius: '20px',
                        maxWidth: '300px',
                        zIndex: 900,
                        // minHeight: '150px',
                        transition: 'all 0.4s ease', position: 'fixed', top: element1.top, left: element1.left,
                    }}
                >
                    <p className='w-100 p-1 rounded bg-white' style={{
                    }}>{loading ? 'Generating... Please wait' :
                        `${tutorialText.howTo}`}</p>
                    <p className='text-white'>
                        {loading ? <CircularProgress /> :
                            `${tutorialText.furtherInfo}`}
                    </p>
                    {!loading &&
                        <div className='d-flex text-white justify-content-between'>
                            <div onClick={() => { prevTut() }}>
                                <FastRewind color='inherit' />
                            </div>
                            <div onClick={() => { nextTut() }}>
                                <FastForward color='inherit' />
                            </div>
                        </div>
                    }
                </div>

            }

            <div>
                <TopNav login={loggedIn} />
            </div>
            <div style={{
                position: 'relative',
            }}>
                {/* <p> {prompt}</p> */}
                <div className='row justify-content-around'>
                    <div className='d-none d-lg-block col-lg-3 p-4  contentpart'>
                        {!loading ? '' : <p className='text-center'><CircularProgress /></p>}
                        {notify == '' ? '' : <p className='text-center'>{notify}</p>}
                        <h4>Table of Contents will be showned here</h4>
                        <div>
                            <div>
                                <div id={tutorialIndex == 4 && 'element2'} style={{ position: 'relative' }}>
                                    <p className='fw-bold'>Title: {generated.title}</p>
                                    <div onClick={() => { setEditTit(!editTit) }} style={{ position: 'absolute', top: 0, right: -30, }}>
                                        <Edit />
                                    </div>
                                </div>
                                {editTit &&
                                    <input type="text"
                                        onBlur={() => {
                                            window.localStorage.setItem('gent', JSON.stringify(editTitle));
                                            setRand(Math.random())
                                        }}
                                        onChange={(e) => {
                                            setEditTitle({ ...editTitle, title: e.target.value })
                                        }}
                                        className='form-control'
                                        value={editTitle.title}
                                    />
                                }
                            </div>
                            <div className='mb-3'>
                                <div style={{ position: 'relative' }}>
                                    <p className=''>Sub-Title: {generated.subtitle}</p>
                                    <div onClick={() => { setEditSub(!editSub) }} style={{ position: 'absolute', top: 0, right: -30, }}>
                                        <Edit />
                                    </div>
                                </div>
                                {editSub &&
                                    <input type="text"
                                        onBlur={() => {
                                            window.localStorage.setItem('gent', JSON.stringify(editTitle));
                                            setRand(Math.random())
                                        }}
                                        onChange={(e) => {
                                            setEditTitle({ ...editTitle, subtitle: e.target.value })
                                        }}
                                        className='form-control'
                                        value={editTitle.subtitle}
                                    />
                                }
                            </div>
                            <br />
                            <div>
                                <button onClick={generateDescription} className='buttonComp'>Generate Description</button>
                            </div>
                        </div>
                        <br />
                        <br />
                        <hr />
                        {contents != '' && <div id={tutorialIndex == 6 && 'element2'}>
                            {JSON.parse(contents).contents.map((item, index) => {
                                return (
                                    < >
                                        <AccordionComp key={index} number={index} chapters={item} />
                                    </>
                                )
                            })}
                        </div>
                        }
                    </div>
                    <div className='text-end d-flex d-lg-none'>
                        {!loading ? '' : <p className='text-center'><CircularProgress /> </p>}
                        {notify == '' ? '' : <p className='text-center'>{notify}</p>}
                        <button className='buttonComp' onClick={() => { setShowTable(true) }}>
                            Table of Contents
                        </button>
                        <button className='buttonComp' onClick={() => { setShowControls(true) }}>
                            Prompts
                        </button>
                    </div>
                    <div className='col-lg-8  border border-1 '>
                        <EditorFile description={description} tutorialIndex={tutorialIndex} title={generated} contentTable={contents} fullcontent={fullbookContent} />
                    </div>
                    <Draggable cancel=".no-drag">
                        <div className='text-end col-1 container d-flex justify-content-end' style={{
                            position: 'fixed',
                            top: '15%',
                            right: 0,
                        }}>
                            <CToaster ref={toaster} push={toast} placement="top-end" />
                            <div style={{
                                height: '60px',
                                width: '60px',
                                borderRadius: '50px',
                                borderWidth: '1px',
                            }} className='p-1 shadow bg-secondary text-white fw-bold d-flex align-items-center justify-content-center  text-center'>
                                <p onClick={() => { setShowControls(true) }} className='no-drag'>
                                    <Menu />
                                </p>
                            </div>
                        </div>
                    </Draggable>
                    {
                        showcontrol &&
                        <div
                            style={{
                                position: 'fixed',
                                right: 0,
                            }}

                            className='p-3 bg-white col-lg-4 shadow'>

                            <div className='d-flex justify-content-between'>
                                <div className='text-end' onClick={() => { setLocked(!locked) }}>
                                    {
                                        locked ?
                                            <LockOutlined />
                                            :
                                            <LockOpen />

                                    }
                                </div>

                                <div className='text-end' onClick={() => { setShowControls(false) }}>
                                    <Close />
                                </div>

                            </div>


                            {!loading ? '' : <p className='text-center'><CircularProgress /> </p>}
                            {notify == '' ? '' : <p className='text-center'>{notify}</p>}
                            <form
                                onSubmit={generateIntros}>
                                <div className='text-start  justify-content-around align-items-start'>
                                    <div className='mb-3'>
                                        <label className='fw-bold'>Keywords (Please Enter Keywords seperated by comma)</label>
                                        <input id={tutorialIndex == 0 && 'element2'} value={keywordsArray} required onChange={(e) => { setKeyWordsArray(e.target.value) }} type="text" className='form-control' placeholder='enter your keywords seperated by coma' />
                                        <p className='fw-bold mt-2'>
                                            {keywordsArray}
                                        </p>
                                    </div>
                                    <div className='mb-3'>
                                        <label className='fw-bold'>Category (Please Enter Book Category)</label>
                                        <input id={tutorialIndex === 1 && 'element2'} value={category}
                                            onBlur={() => {
                                                window.localStorage.setItem('category_type', category);
                                                setRand(Math.random())
                                            }}
                                            required onChange={(e) => { setCategory(e.target.value) }} type="text" className='form-control' placeholder='enter your book category eg. Nonfiction > Health & Fitness > Diet & Nutrition > Weight Loss' />
                                    </div>
                                    <div className='mb-3'>
                                        <label className='fw-bold'>Page Number (Please Enter Page Number)</label>
                                        <input id={tutorialIndex === 2 && 'element2'} value={pageNumber}
                                            onBlur={() => {
                                                window.localStorage.setItem('page_number', pageNumber);
                                                setRand(Math.random())
                                            }}
                                            required onChange={(e) => { setPageNumber(e.target.value) }} type="number" className='form-control' placeholder='enter expected page number' />
                                    </div>
                                    <div className='py-4 text-center'>
                                        <button id={tutorialIndex == 3 && 'element2'} type='submit' className='btn btn-danger mb-2'>Generate Title</button>
                                    </div>
                                </div>
                            </form>
                            <div

                                className='mt-3 align-items-center text-lg-center '>
                                <div className='mb-2'>
                                    <button id={tutorialIndex == 5 && 'element2'} type='button' onClick={generateContents} className='buttonComp'>Generate Table Of Contents</button>
                                </div>
                                <div className='mb-2'>
                                    <button id={tutorialIndex == 7 && 'element2'} type='button' onClick={WriteAChapter} className='buttonComp'>Generate Full Book</button>
                                </div>
                                <div className='mb-2'>
                                    <button id={tutorialIndex == 9 && 'element2'} type='button' onClick={ExpandChapters} className='buttonComp'>Expand Book Chapters</button>
                                </div>

                            </div>
                        </div>
                    }



                </div>
                {
                    showTable &&
                    <div style={{
                        position: 'absolute',
                        top: 0,
                    }} className='d-lg-block col-lg-3 p-4  bg-white contentpart'>
                        {!loading ? '' : <p className='text-center'><CircularProgress /></p>}
                        {notify == '' ? '' : <p className='text-center'>{notify}</p>}
                        <div className='text-end' onClick={() => { setShowTable(false) }}>
                            <Close color="red" />
                        </div>
                        <h4 >Table of Contents will be showned here</h4>
                        <div>
                            <div>
                                <div style={{ position: 'relative' }}>
                                    <p className='fw-bold'>Title: {generated.title}</p>
                                    <div onClick={() => { setEditTit(!editTit) }} style={{ position: 'absolute', top: 0, right: -30, }}>
                                        <Edit />
                                    </div>
                                </div>
                                {editTit &&
                                    <input type="text"
                                        onBlur={() => {
                                            window.localStorage.setItem('gent', JSON.stringify(editTitle));
                                            setRand(Math.random())
                                        }}
                                        onChange={(e) => {
                                            setEditTitle({ ...editTitle, title: e.target.value })
                                        }}
                                        className='form-control'
                                        value={editTitle.title}
                                    />
                                }
                            </div>
                            <div className='mb-3'>
                                <div style={{ position: 'relative' }}>
                                    <p className=''>Sub-Title: {generated.subtitle}</p>
                                    <div onClick={() => { setEditSub(!editSub) }} style={{ position: 'absolute', top: 0, right: -30, }}>
                                        <Edit />
                                    </div>
                                </div>
                                {editSub &&
                                    <input type="text"
                                        onBlur={() => {
                                            window.localStorage.setItem('gent', JSON.stringify(editTitle));
                                            setRand(Math.random())
                                        }}
                                        onChange={(e) => {
                                            setEditTitle({ ...editTitle, subtitle: e.target.value })
                                        }}
                                        className='form-control'
                                        value={editTitle.subtitle}
                                    />
                                }

                            </div>
                        </div>

                        {contents != '' && <div>
                            {JSON.parse(contents).contents.map((item, index) => {
                                return (
                                    < >
                                        <AccordionComp key={index} number={index} chapters={item} />
                                    </>
                                )
                            })}
                        </div>
                        }
                    </div>
                }
            </div>
        </div >
    )
}
