import React from 'react'
import { Personas } from '../personalities'

export default function PersonalitiesRev({ choosePersona }) {

    return (
        <div className='px-2 px-lg-5 py-5'>

            <div className='text-center'>
                <h2>Meet Our AI-Powered Literary Personalities</h2>
                <hr />
            </div>
            <div className='row align-items-center g-2 g-lg-4 justify-content-center'>
                {
                    Personas.map((item, index) => {
                        return (
                            <div className='p-lg-5 col-lg-6'>
                                <div key={index} className="border border-1 rounded-3 p-2 p-lg-5 align-items-center">
                                    <div className="text-center">
                                        <img className='img-fluid' src={item.image} height={300} alt={item.name} loading="lazy" />
                                    </div>

                                    <div className='text-center'>
                                        <h4>
                                            {item.name} - ({item.title})
                                            <hr />
                                        </h4>

                                        <p>{item.description}</p>
                                    </div>

                                    <div className='d-flex justify-content-center'>
                                        <button onClick={() => { choosePersona(item, index) }} className='buttonComp text-white'>Interact</button>
                                    </div>

                                </div>
                            </div>
                        )
                    })
                }
            </div>

        </div>
    )
}
