import React, { useState } from 'react'

export default function AccordionComp({ chapters, number }) {
    const [show, setShow] = useState(false)
    return (
        <div>
            <div className=' justify-content-between mb-3'>

                <div onClick={() => { setShow(!show) }} className={show ? 'border border-1  rounded-1 text-light bg-dark  p-1' : 'border border-1 rounded-1 text-dark p-1'}>
                    {chapters.chapter_title}
                </div>
                {/* <button onClick={() => {
                    setSearchKeyWord(`Write "${chapters.chapter_title}" taking consideration of the following sections: ${chapters.subsections.map((item, index) => { return `${number}.${index + 1} ${item.title}`; }).join(', ')}`);

                    WriteChapters(searchKeyWord)
                }} className='btn btn-sm btn-primary'>Write</button> */}
            </div>


            <div className={show ? 'me-3' : 'me-3 d-none'}>

                {chapters.subsections.map((item, index) => {
                    return (
                        <div key={index} className='ms-3 border border-1 rounded-1 mb-2 p-2'>
                            {item.title} </div>
                    )
                })}
            </div>

        </div>
    )
}
