import { Close, CloseRounded, MenuOpen } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Login from '../AuthComp/Login';
import Signup from '../AuthComp/Signup';
import { menuitems } from './menus'

function TopNav(props) {

    const navigate = useNavigate();
    const { login } = props;
    const [activeMenu, setactiveMenu] = useState('')
    const [showMenu, setShowMenu] = useState(false);
    const [showSignUp, setShowSignUp] = useState(false);

    const [loggedUser, setLoggedUser] = useState('');
    const [activeForm, setActiveForm] = useState('login');



    useEffect(() => {

        if (!login) {
            setLoggedUser(JSON.parse(window.localStorage.getItem('book_user')));
        }

        setShowSignUp(login)
    }, [login])



    // console.log(userDetails)
    return (

        <div className='navDesign bg-main text-light px-5 py-4 d-flex align-items-center justify-content-between'>
            <a className='d-flex nav-link' href='/'>
                <h2 className='logA bg-orange fw-bold'>
                    B</h2>
                <h2>
                    OOKERY
                </h2>
            </a>

            <ul className='navlist d-none d-lg-flex justify-content-around  nav-link'>

                {
                    menuitems.map((item, index) => {
                        return (
                            <li key={index + 1} className='nav-link   me-4'>
                                <a className='navlink' onMouseEnter={() => { setactiveMenu(index) }} href={item.path}>{item.title}</a>
                                <div onMouseLeave={() => { setactiveMenu('') }} onMouseEnter={() => { setactiveMenu(index) }} className={activeMenu === index && item.sub_menu.length != 0 ? 'position-absolute menusubitem' : 'd-none'}>
                                    {
                                        item.sub_menu.map((sub_menu, index) => {
                                            return (
                                                <div className='mb-3'  >

                                                    <a className='nav-link ' href={sub_menu.path} >
                                                        {sub_menu.title}
                                                    </a>
                                                </div>

                                            )
                                        })
                                    }
                                </div>


                            </li>
                        )
                    })
                }
            </ul>
            <div className='d-none d-lg-block'>

                {
                    !login && loggedUser !== null ?
                        <div>
                            Welcome  {loggedUser.first_name}
                            <button onClick={() => {
                                window.localStorage.removeItem('book_user');
                                window.location.reload();
                            }} className='btn btn-danger btn-sm ms-3'> Log Out </button>
                        </div>
                        :

                        <button onClick={() => {
                            setShowMenu(false)
                            setShowSignUp(true)
                        }} className='buttonComp'> Get Started </button>
                }

            </div>

            <div onClick={() => { setShowMenu(true) }} className='d-lg-none'>
                <MenuOpen size={50} />
            </div>

            {
                showSignUp &&
                <div className='justify-content-center' style={
                    {
                        width: '100%',
                        height: '100vh',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        backgroundColor: '#0D3F8460',
                        zIndex: 1000,
                    }}>
                    <div style={{ top: '20%', position: 'relative' }} className='bg-white text-dark p-lg-5 p-2 col-lg-5 m-auto rounded'>
                        {/* {route == '/' && */}
                        <div onClick={() => {
                            setShowSignUp(false)
                            navigate('/')
                        }} className='text-end'>
                            <CloseRounded />
                        </div>
                        {/* } */}

                        <center >
                            {
                                activeForm === 'login' ?
                                    <p className='fs-4'>Login</p>

                                    :
                                    <p className='fs-4'>Sign Up</p>
                            }
                        </center>
                        {
                            activeForm === 'login' ?

                                <Login setActiveForm={setActiveForm} />

                                :
                                <Signup setActiveForm={setActiveForm} />
                        }

                    </div>

                </div>
            }

            {showMenu &&
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    height: '100vh',
                    width: '100%',
                    zIndex: 1000,
                }} className='d-lg-none bg-main p-5 text-light'>

                    <div onClick={() => { setShowMenu(false) }} className='text-end'>
                        <Close size={30} color='white' />
                    </div>
                    <ul className='navlist text-light justify-content-around  nav-link'>

                        {
                            menuitems.map((item, index) => {
                                return (
                                    <li key={index} className='nav-link   me-4'>
                                        <a className='navlink' onMouseEnter={() => { setactiveMenu(index) }} href={item.path}>{item.title}</a>
                                        <div onMouseLeave={() => { setactiveMenu('') }} onMouseEnter={() => { setactiveMenu(index) }} className={activeMenu === index && item.sub_menu.length != 0 ? 'position-absolute menusubitem' : 'd-none'}>
                                            {
                                                item.sub_menu.map((sub_menu, index) => {
                                                    return (
                                                        <div className='mb-3'  >

                                                            <a className='nav-link ' href={sub_menu.path} >
                                                                {sub_menu.title}
                                                            </a>
                                                        </div>

                                                    )
                                                })
                                            }
                                        </div>


                                    </li>
                                )
                            })
                        }
                    </ul>

                    <div className=''>

                        {
                            !login && loggedUser !== null ?
                                <>
                                    <div className='mb-3'>
                                        Welcome  {loggedUser.first_name}
                                    </div>
                                    <button onClick={() => {
                                        window.localStorage.removeItem('book_user');
                                        window.location.reload();
                                    }} className='btn btn-danger btn-sm'> Log Out </button>
                                </>

                                :
                                <button onClick={() => {
                                    setShowSignUp(true)
                                    setShowMenu(false)
                                }} className='buttonComp'> Get Started </button>
                        }
                    </div>
                </div>

            }
        </div>
    )
}


function BottomNav() {

    return (
        <>
            <div className='bottomNav px-2 px-lg-5 bg-footer py-4'>

                <div className='row  justify-content-between text-center  text-lg-start pt-4'>

                    <div className='col-lg-3 mb-3'>
                        <a className='d-flex justify-content-center nav-link' href='/'>
                            <h2 className='logB bg-main  fw-bold'>
                                B</h2>
                            <h2>
                                OOKERY
                            </h2>
                        </a>
                    </div>

                    <div className='col-lg-3'>
                        <ul className='navlist'>
                            <h6 className='me-4'>About Us</h6>

                            <li className='me-4'> <span>Terms and Conditions </span></li>
                            <li className='me-4'> <span>Privacy Policy </span></li>
                            <li className='me-4'> <span>Contact Us </span></li>
                            <li className='me-4'> <span>FAQ </span></li>
                        </ul>
                    </div>

                    <div className='col-lg-3'>

                        <ul className='navlist'>
                            <h6 className='me-4'>Policies</h6>

                            <li className='me-4'> <span>Terms and Conditions </span></li>
                            <li className='me-4'> <span>Privacy Policy </span></li>
                            <li className='me-4'> <span>Contact Us </span></li>
                            <li className='me-4'> <span>FAQ </span></li>
                        </ul>
                    </div>

                    <div className='col-lg-3 subscribe p-4'>
                        <h6 className='mb-3'>Subscribe to our Newsletter</h6>
                        <div className='input-group mb-3'>
                            <input type="text" className='form-control' placeholder='Enter your email' />
                            <button className='buttonComp'>Subscribe</button>
                        </div>
                        <p>Get fantastic updates on our releases and changes made to our programs</p>
                    </div>
                </div>


                <div className='text-center'>
                    <p>Copyright &copy; Octinum Creatives 2023</p>
                </div>
            </div>
        </>
    )
}
export default TopNav
export { BottomNav }