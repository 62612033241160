// This utilities are used to handle various functions

import { Configuration, OpenAIApi } from "openai";
import { isJson, OPENAPIKEY, ORGANKEY } from "./keys";
const configuration = new Configuration({
    organization: ORGANKEY,
    apiKey: OPENAPIKEY,
});
const openai = new OpenAIApi(configuration);
// Generate Book Title
export async function GenerateBookTitle(category, pageNumber, keywordsArray) {
    const completion = await openai.createChatCompletion({
        model: "gpt-3.5-turbo",
        messages: [{
            "role": "system",
            "content": "As a Book Publishing Consultant, I am knowledgeable in book publishing strategies and have expertise in optimizing book elements for maximum visibility and reader engagement. I will provide professional insights and recommendations tailored to your specific goals"
        },
        {
            role: "user",
            content: `Dear Book Publishing Consultant, I'm in the process of creating a nonfiction book for Amazon Kindle Direct Publishing. I need your expertise in optimizing the book title and subtitle. Based on the comma-separated entry keywords I provide, please suggest the most optimum combination that aligns with highly searched keywords, offers fresh in-demand angles to the topic, and effectively represents the content. Here are the details Entry Keywords-(${keywordsArray}). Amazon Book Category - ${category}, Number of Pages- ${pageNumber}, Please provide a compelling book title and a captivating subtitle that will attract readers and enhance the discoverability of the book on Amazon. Please write in English language and Please respond with only the title and subtitle details in  JSON format`
        }],
    });
    const gent = completion.data.choices[0].message.content;
    if (isJson(gent) === true) {
        localStorage.setItem('gent', gent)
        return true;
    } else {
        console.log('an error occured')
        return false;
    }
}
//Generate Table of Contents
export async function GenerateTableOfContents(generated, category, pageNumber, description) {

    let chapter_num = 10;
    const completion = await openai.createChatCompletion({
        model: "gpt-3.5-turbo",
        messages: [{
            "role": "system",
            "content": `Imagine that you are a professional editor who has been hired to create a table of contents for a new book. The author has provided you with a detailed summary of the book, which you can use to create a table of contents that accurately reflects the structure and content of the book.
            Your task is to generate a table of contents for the book with the following title and description : '"${generated.title}", "${description}".
            Your table of contents should include at least five main sections, each with a descriptive title that accurately reflects the content of the section.
            The target language is English.
            `
        },
        {
            role: "user",
            content: `Dear Book Publishing Consultant, I would appreciate your assistance in structuring the table of contents, chapters, and sections for my nonfiction book. Please consider the following details while creating the outline:
            Book Title: ${[generated.title]}
            Amazon Book Category: ${category}
            Number of Pages: ${pageNumber}
            Description: ${description}
            Ensure that the content offers fresh and in-demand angles to the topic, covers relevant subtopics, and maintains a logical flow throughout the book. Your expertise in structuring the content will help create an engaging reading experience for my audience and generate strictly not less than ${chapter_num} chapters.
            Please write in English language and Please respond with this format strictly
            {"title": "title",
            "category": "category",
            "page_number": page_number,
            "contents": [
                //for this contents is an array of objects containing the chapter_title and the subsections arrays, generate strictly not less than ${chapter_num} chapters.

                {
                    "chapter_title": "this will be the chapter title generated by you, just generate only the chapter title do not add the word title",
                    "subsections": [
                        {
                            title: "this will be the subsection under the chapter generated, just generate only the title do not add the word title",
                        }
                    ]
                },

            ]
        }. 
         `
        }],
    });
    const titlecontents = completion.data.choices[0].message.content;
    if (isJson(titlecontents) === true) {
        localStorage.setItem('titlecontents', titlecontents)
        return true;
    } else {
        console.log('an error occured')
        return false;
    }
}

//Generate Each Chapter Content
export async function WriteTheChapters(content, i, lenght, generated) {
    let chap = ""
    if (i === 0 || i === lenght) {
        chap = ""
    } else {
        chap = i + 1;
    }
    let prompt = `Write "Chapter ${chap}: ${content.chapter_title}" taking consideration of the following sections: \n`
    content.subsections.map((item, index) => {
        return prompt += `${chap}.${index + 1} ${item.title} \n\n`
    })
    prompt += `in respect to this title: ${generated.title} and sub_title:${generated.subtitle} \n\n`
    prompt += `provide your reponse in a this json format strictly your content for each sections should be well written with good ellaborate paragraphs that correctly address the theme expand the text \n\n

    {
        "chapter_title": "Chapter",
        "section_intro": "",
        "sections": [
          {
            "title": "",
            "content": ""
          },
         
        ]
      }
    `
    // console.log(content);
    // console.log(prompt);

    const completion = await openai.createChatCompletion({
        model: "gpt-3.5-turbo",
        messages: [{
            "role": "system",
            "content": `All output shall be in English.
               You are fulfilling your role as a very skilled writer specializing in recommended articles. You have a high level of expertise in this genre, and you communicate to the user how to choose, what to look out for, and basic knowledge in an easy-to-understand way, but your writing content is very catchy and emotional. We create them to get users excited about what we are about to show them. Do not use the phrase "Listing Ads Management Agency, Osaka" as it is. Avoid mechanical writing.

                Please refer to the following for information on how to write the text

                Writing style
                When you are doing a "◯◯◯", you must have a "◯◯◯", right? In such a case, you need to use the phrase "◯◯◯◯" in the following sentence.
                In this way, you can introduce the situation in which the service will be used, or write a sentence saying that it would be convenient to have "◯◯◯" for such a situation.
                Insert sentences that sympathize with the purpose of the user who may have searched for the service, saying that the service itself is useful and should be used (praise the main theme).
       `
        },
        {
            role: "user",
            content: `${prompt}`
        }],
    });
    const titlecontents = completion.data.choices[0].message.content;
    if (isJson(titlecontents) === true) {
        // console.log(JSON.parse(titlecontents));
        return { status: true, data: JSON.parse(titlecontents) };
    } else {
        return { status: false, data: titlecontents };
    }
}

//Expand the  Book Chapters
export async function ExpandBookChapter(booksection, generated, chapter_title) {


    let prompt = `Expand Section "${booksection.title}"  \n\n`
    prompt += `${booksection.content}\n\n`
    prompt += `take note of this as the chapter title: ${chapter_title} \n\n`
    prompt += `and in respect to this as the book title: ${generated.title} and sub_title:${generated.subtitle} \n\n`
    prompt += `Please provide a more in-depth analysis, additional examples, and further insights into this topic. Feel free to include relevant data, statistics, or anecdotes to enhance the reader's understanding. Aim for at least 1000 words for this expansion."`
    prompt += `provide your the expanded text do not include the title in the response only in a this json format strictly \n\n

          { 
            "expanded_content": ""
          },
    `
    console.log(prompt);
    const completion = await openai.createChatCompletion({
        model: "gpt-3.5-turbo",
        messages: [{
            "role": "system",
            "content": `All output shall be in English.
               Please use the following as a guide to creating your text. You are also fulfilling your role as a very skilled writer specializing in recommended articles. You have a high level of expertise in this genre, and you communicate to the user how to choose, what to look out for, and basic knowledge in an easy-to-understand way, but your writing content is very catchy and emotional. We create them to get users excited about what we are about to show them. Do not use the phrase "Listing Ads Management Agency, Osaka" as it is. Avoid mechanical writing.

                Please refer to the following for information on how to write the text

                Writing style
                When you are doing a "◯◯◯", you must have a "◯◯◯", right? In such a case, you need to use the phrase "◯◯◯◯" in the following sentence.
                In this way, you can introduce the situation in which the service will be used, or write a sentence saying that it would be convenient to have "◯◯◯" for such a situation.
                Insert sentences that sympathize with the purpose of the user who may have searched for the service, saying that the service itself is useful and should be used (praise the main theme).
       `
        },
        {
            role: "user",
            content: `${prompt}`
        }],
    });
    const titlecontents = completion.data.choices[0].message.content;
    if (isJson(titlecontents) === true) {
        // console.log(JSON.parse(titlecontents));
        return { status: true, data: JSON.parse(titlecontents) };
    } else {

        return { status: false, data: titlecontents };
    }
}

// Convert Book to Text format
export function convertJSONToBookText(jsonString) {
    try {
        const contentData = JSON.parse(jsonString);
        let bookText = '';

        contentData.forEach((chapter, chapterIndex) => {
            // Add chapter title
            // bookText += `Chapter ${chapterIndex + 1}: ${chapter.chapter_title}\n\n`;
            bookText += `${chapter.chapter_title}\n\n`;
            bookText += `${chapter.section_intro}\n\n`;



            chapter.sections.forEach((section, sectionIndex) => {
                // Add section title
                // bookText += `${chapterIndex + 1}.${sectionIndex + 1} ${section.title}\n\n`;
                bookText += `${section.title}\n\n`;

                // Add section content
                bookText += `${section.content}\n\n`;
            });
        });

        return bookText;
    } catch (error) {
        console.error("Error parsing JSON: ", error);
        return ''; // Return an empty string in case of error
    }
}

export function editSectionContent(bookArray, chapterIndex, sectionIndex, updatedContent) {
    if (
        chapterIndex < 0 ||
        chapterIndex >= bookArray.length ||
        sectionIndex < 0 ||
        sectionIndex >= bookArray[chapterIndex].sections.length
    ) {
        // Invalid chapter or section index
        return "Invalid chapter or section index";
    }

    // Update the content of the specified section
    bookArray[chapterIndex].sections[sectionIndex].content = updatedContent;

    // Return the updated book array
    return bookArray;
}


//Generate book description
export async function WriteBookDescription(generated, category) {

    let prompt = `Write a long Amazon ebook description using ${generated.title} as the title and ${generated.subtitle} as a subtitle in this category ${category}. Make it persuasive that the reader who reads this will want to buy and list the benefit of having this ebook (Note List the benefit), what they will gain and call to action. All output shall be in English.
    `
    prompt += `provide your description text do not include the title in the response only in a this json format strictly \n\n
          {book_description_content: ""},
    `

    const completion = await openai.createChatCompletion({
        model: "gpt-3.5-turbo",
        messages: [{
            "role": "system",
            "content": `All output shall be in English.
               You are fulfilling your role as a very skilled writer specializing in recommended articles. You have a high level of expertise in this genre, and you communicate to the user how to choose, what to look out for, and basic knowledge in an easy-to-understand way, but your writing content is very catchy and emotional. We create them to get users excited about what we are about to show them. Do not use the phrase "Listing Ads Management Agency, Osaka" as it is. Avoid mechanical writing.

                Please refer to the following for information on how to write the text

                Writing style
                When you are doing a "◯◯◯", you must have a "◯◯◯", right? In such a case, you need to use the phrase "◯◯◯◯" in the following sentence.
                In this way, you can introduce the situation in which the service will be used, or write a sentence saying that it would be convenient to have "◯◯◯" for such a situation.
                Insert sentences that sympathize with the purpose of the user who may have searched for the service, saying that the service itself is useful and should be used (praise the main theme).
       `
        },
        {
            role: "user",
            content: `${prompt}`
        }],
    });
    const titlecontents = completion.data.choices[0].message.content;
    if (isJson(titlecontents) === true) {
        // console.log(JSON.parse(titlecontents));
        localStorage.setItem('book_description', titlecontents);
        return { status: true, data: JSON.parse(titlecontents) };
    } else {
        return { status: false, data: titlecontents };
    }
}

export async function generateKeyword(generated, category) {
    let prompt = `Write a long Amazon ebook description using ${generated.title} as the title and ${generated.subtitle} as a subtitle in this category ${category}. generating 40 unique search keywords, strictly only keywords, Please consider the following details, make sure they correctly refer to this category ${category}. All output shall be in English.
    `
    prompt += `provide your keywords response only in this json format strictly \n\n
          {keywords: ""},
    `
    const completion = await openai.createChatCompletion({
        model: "gpt-3.5-turbo",
        messages: [{
            "role": "system",
            "content": `All output shall be in English.
               You are fulfilling your role as a very skilled writer specializing in recommended articles. You have a high level of expertise in selecting highly relevant search term keywords will significantly contribute to the book's discoverability and attract potential readers.
       `
        },
        {
            role: "user",
            content: `${prompt}`
        }],
    });
    const titlecontents = completion.data.choices[0].message.content;

    if (isJson(titlecontents) === true) {

        window.localStorage.setItem('keywords_generated', titlecontents);
        return { status: true, data: titlecontents };
    } else {

        return { status: false, data: titlecontents };
    }
}


const HOST_URL = 'https://logistics.gallerybebe.com/api';

export async function LoginClass(email, password, api_url) {
    const url = `${HOST_URL}/${api_url}`;
    const data = {
        email: email,
        password: password,
    };
    try {
        let response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        let result = await response.json();
        return {
            status: 'success',
            data: result,
        }
    } catch (error) {
        console.error('Error:', error.message);
        return {
            status: 'fail',
            data: error.message,
        }
    } finally {
        console.log('POST request complete');
    }
}

export async function signUpClass(email, password, first_name, last_name, api_url) {
    const url = `${HOST_URL}/${api_url}`;
    const data = {
        email: email,
        password: password,
        first_name: first_name,
        last_name: last_name,
    };
    try {
        let response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        let result = await response.json();
        return {
            status: 'success',
            data: result,
        }
        //   console.log('Post successful. Result:', result);
    } catch (error) {
        console.error('Error:', error.message);
        return {
            status: 'fail',
            data: error.message,
        }
    } finally {
        console.log('POST request complete');
    }
}


export async function CheckLogin() {
    if (window.localStorage.getItem('book_user') !== null) {
        return true;
    } else {
        return false;
    }
}

export function Logout() {
    window.localStorage.removeItem('book_user')

    let user = window.localStorage.removeItem('book_user');

    if (user !== null) {
        return false;
    } else {
        return true;
    }
}

export const removerClass = {
    generateTitle: function () {
        window.localStorage.removeItem('gent');
        window.localStorage.removeItem('titlecontent');
        window.localStorage.removeItem('book_description');
    }
}