export const OPENAPIKEY = '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';
export const ORGANKEY = 'org-YQqtAXqbAVGIVSpWrSorpiRT';


// Json checker function

export const isJson = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}