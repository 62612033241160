export const tutorialJson = {

    editorPage: [
        {
            letsGetStarted: {
                instructions: "Hi, I would be guiding you today on how to use our Editor to generate your Manuscript.",
            },
        },
        {
            generateTitle: {
                instructions: [
                    {
                        item: "keyWordsRef",
                        howTo: "First we have to type in our Keywords in the input field below.",
                        furtherInfo: "Your Keywords should contain word related to what you want to write about, you can try Blood Sugar, Stress, Fatigue, Water Drinking",

                    },
                    {
                        item: "categoryRef",
                        howTo: "Now we type in our Category in the input field next after it",
                        furtherInfo: "Ensure you put in category from Amazon KDP recognized categories. It should be something like this Non-Fiction>Health>Lifestyle and others."
                    },
                    {
                        item: "pageNumberRef",
                        howTo: "Please Enter your page number here.",
                        furtherInfo: "I recommend 40 pages",
                    },
                    {
                        item: "generateTitleRef",
                        howTo: "Click on the Generate Title",
                        furtherInfo: "",
                    },
                    {
                        item: "titleDisplayRef",
                        howTo: "Yeaaaa! your Title has been generated!!",
                        furtherInfo: "",
                    },
                    {
                        item: "tableofContentsRef",
                        howTo: "Now Let Generate Our Table of Contents!",
                        furtherInfo: "Click on the Generate Table of Contents button",
                    },
                    {

                        item: "titleDisplayRef",
                        howTo: "Yeaaaa! your Table of Content  has been generated!!",
                        furtherInfo: "Great! Work",
                    },
                    {
                        item: "tableofContentsRef",
                        howTo: "Now Let the book proper!",
                        furtherInfo: "Click on the Generate Full Book Button",
                    },
                    {
                        item: "tableofContentsRef",
                        howTo: "Great!👍 Your Book Has Been Generated",
                        furtherInfo: "Read through and lets Expand it next!",
                    },
                    {
                        item: "tableofContentsRef",
                        howTo: "Lets add more text to the book 😉",
                        furtherInfo: "Click on Expand Book chapters",
                    },
                    {
                        item: "tableofContentsRef",
                        howTo: "Perfect!👍 Your Book Has Fully Been Generated",
                        furtherInfo: "Read through and you can export to your text editor!",
                    },
                ]
            },
            generateTableOfContents: {
                instructions: [
                    {
                        item: "modalRef",
                        howTo: "You should have created a Title , if No please click the button by the left to generate a Title",
                        furtherInfo: "",
                    },
                    {
                        item: "menuButtonRef",
                        howTo: "Click on the Menu Button to open prompts menu",
                        furtherInfo: "",
                    },
                    {
                        item: "menuLockInfo",
                        howTo: "Click on the Key Icon to lock menu in place",
                        furtherInfo: "",
                    },
                    {
                        item: "tableOfContentsBtnRef",
                        howTo: "Click on Generate Table of Contents Button to generate a table of contents",
                        furtherInfo: "This might take a little while please be patient",
                    },
                    {
                        item: "tableOfContentsDisplay",
                        howTo: "Your table of contents will be displayed here",
                        furtherInfo: "Click on a content to show Sub-Headings",
                    }
                ]
            }
        }
    ]
}