import React, { useState } from 'react'
import { RemoveRedEye } from '@mui/icons-material'
import { LoginClass, } from '../../utilities';
import Swal from 'sweetalert2';

function Login({ setActiveForm }) {

    const [showpass, setShowPass] = useState(false);

    const [userDetails, setDetails] = useState({
        email: '',
        password: '',
    })


    function Login(e) {
        e.preventDefault()
        LoginClass(userDetails.email, userDetails.password, 'bookwriter_login').then((response) => {
            let result = response;
            if (result.data.status == 'success') {
                window.localStorage.setItem('book_user', JSON.stringify(result.data.data));
                Swal.fire({
                    title: 'Success',
                    text: 'Login Successfully',
                    timer: 1000,
                })
                window.location.reload();
            } else {
                Swal.fire({
                    title: 'Error',
                    text: result.data.message,
                    timer: 1000,
                })
            }
        })
    }



    return (
        <form onSubmit={Login}>
            <div className='mb-3'>
                <label htmlFor='email_address'>Email Address</label>
                <input onChange={(e) => { setDetails({ ...userDetails, email: e.target.value }) }} type="text" name="email_address" className='form-control' placeholder='please enter your email address' />
            </div>
            <div className='mb-3'>
                <label htmlFor='password'>Password</label>
                <span className='form-control '>
                    <input onChange={(e) => { setDetails({ ...userDetails, password: e.target.value }) }} type={showpass ? 'text' : "password"} className='border-0 col-10' name="password" placeholder='please enter your password' />
                    <span onClick={() => { setShowPass(!showpass) }} className='col-2'>
                        <RemoveRedEye />
                    </span>
                </span>

            </div>

            <button className='buttonComp'>Login</button>
            <p>Don't have an account ? <a onClick={() => { setActiveForm('signup') }} >Sign up</a></p>
        </form>
    )
}

export default Login
