
import React, { useEffect, useState } from 'react'


export default function EditorFile({ title, description, contentTable, fullcontent, tutorialIndex }) {
    const [tableOfContent, setTableOfContent] = useState('');
    const [generated, setGenerated] = useState('');
    const [random, setRandom] = useState('');
    const [Mainer, setMainer] = useState('')
    useEffect(() => {
        let MainText = ''
        MainText += `Title: ${title.title}\n sub-title: ${title.subtitle}\n\n`
        MainText += `Book Description: \n\n ${description} \n\n`
        MainText += `${tableOfContent}\n\n`
        MainText += `${fullcontent}\n`
        setMainer(MainText)
    }, [generated, fullcontent, title, random, contentTable, Mainer, description])
    useEffect(() => {
        if (contentTable !== '') {
            const TableOfContent = JSON.parse(contentTable).contents.map((item, indexm) => {
                const chapterTitle = `Chapter ${indexm + 1}-${item.chapter_title}`;
                const subsections = item.subsections.map((sub_item, index) => {
                    return `-${sub_item.title}`;
                }).join('\n');
                return `${chapterTitle}\n${subsections}`;
            })
            const TableText = TableOfContent.join('\n\n');
            setTableOfContent(TableText);
            setRandom(Math.random())
        }
    }, [contentTable, tableOfContent])
    return (
        <>
            <div className=''>
                <textarea id={tutorialIndex === 8 && 'element2'} spellCheck={true} onChange={(e) => { setMainer(e.target.value) }} style={{ textAlign: 'justify' }} className='w-100 form-control p-1 border-0' rows={50} value={Mainer}>
                </textarea>
            </div>

        </>

    )
}
